@media screen and (max-width: 350px) {
  .contactContainer {
    margin-bottom: 0;
  }

  .formContainer {
    width: 100%;
    height: 1350px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    margin: 0;
    overflow: hidden;
  }
  .iframe {
    border-radius: 0px !important;
    box-shadow: none !important;
    width: 100vw !important;
  }

  .heroContent {
    width: 60%;
    margin-top: 8em;
    margin-bottom: 3.5em;
    text-align: center;
  }
  .heroContent > h1 {
    font-size: 2.5em;
  }

  .heroContent > h4 {
    font-size: 1.2em;
  }
  .contactInfo {
    border-radius: 15px;
    margin-top: 60px;
    margin-bottom: 60px;
    box-shadow: 5px 5px 56px 0px rgba(0, 0, 0, 0.5);
    width: fit-content;
  }
  .iconsContainer {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (min-width: 351px) and (max-width: 400px) {
  .contactContainer {
    margin-bottom: 0;
  }
  .formContainer {
    width: 100%;
    height: 1350px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    margin: 0;
    overflow: hidden;
  }
  .iframe {
    border-radius: 0px !important;
    box-shadow: none !important;
    width: 102vw !important;
  }

  .heroContent {
    width: 60%;
    margin-top: 8em;
    margin-bottom: 3.5em;
    text-align: center;
  }
  .heroContent > h1 {
    font-size: 2.5em;
  }

  .heroContent > h4 {
    font-size: 1.2em;
  }
  .contactInfo {
    border-radius: 15px;
    margin-top: 60px;
    margin-bottom: 60px;
    box-shadow: 5px 5px 56px 0px rgba(0, 0, 0, 0.5);
    width: fit-content;
  }
  .iconsContainer {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (min-width: 401px) and (max-width: 770px) {
  .contactContainer {
    margin-bottom: 0;
  }
  .formContainer {
    width: 100%;
    height: 1350px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .iframe {
    border-radius: 0px !important;
    box-shadow: none !important;
    width: 120vw !important;
    padding: 1rem;
  }

  .heroContent {
    width: 60%;
    margin-bottom: 8em;
    margin-bottom: 3.5em;
    text-align: center;
  }
  .heroContent > h1 {
    font-size: 2.5em;
  }

  .heroContent > h4 {
    font-size: 1.2em;
  }
  .contactInfo {
    border-radius: 15px;
    margin-top: 60px;
    margin-bottom: 60px;
    box-shadow: 5px 5px 56px 0px rgba(0, 0, 0, 0.5);
    width: fit-content;
  }
  .iconsContainer {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (min-width: 771px) and (max-width: 900px) {
  .contactContainer {
    margin-bottom: 0;
  }
  .formContainer {
    width: 100%;
    height: 1275px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  .iframe {
    border-radius: 0px !important;
    box-shadow: none !important;
  }

  .heroContent {
    width: 100%;
    margin-top: 8em;
    margin-bottom: 3.5em;
    text-align: center;
  }
  .heroContent > h1 {
    font-size: 3em;
  }

  .heroContent > h4 {
    font-size: 1.5em;
  }
  .contactInfo {
    border-radius: 15px;
    margin-top: 60px;
    box-shadow: 5px 5px 56px 0px rgba(0, 0, 0, 0.5);
    width: fit-content;
  }
  .iconsContainer {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (min-width: 901px) and (max-width: 1000px) {
  .formContainer {
    width: 90vw;
    height: 1000px;
  }
  .heroContent {
    width: 90vw;
    margin-bottom: 8em;
    margin-bottom: 3.5em;
  }
  .heroContent > h1 {
    font-size: 3em;
  }

  .heroContent > h4 {
    font-size: 1.5em;
  }
}

@media screen and (min-width: 1001px) and (max-width: 1200px) {
  .formContainer {
    width: 85vw;
    height: 1000px;
  }
  .heroContent {
    width: 85vw;
    margin-bottom: 8em;
    margin-bottom: 3.5em;
  }
  .heroContent > h1 {
    font-size: 3em;
  }

  .heroContent > h4 {
    font-size: 1.5em;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1300px) {
  .formContainer {
    width: 75vw;
    height: 1000px;
  }
  .heroContent {
    width: 75vw;
    margin-bottom: 8em;
    margin-bottom: 3.5em;
  }
  .heroContent > h1 {
    font-size: 3em;
  }

  .heroContent > h4 {
    font-size: 1.5em;
  }
}

@media screen and (min-width: 1301px) and (max-width: 1600px) {
  .formContainer {
    width: 70vw;
    height: 1000px;
  }
  .heroContent {
    width: 70vw;
    margin-bottom: 9em;
    margin-bottom: 4em;
  }
  .heroContent > h1 {
    font-size: 3.2em;
  }

  .heroContent > h4 {
    font-size: 1.8em;
  }
}
