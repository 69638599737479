.footerContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em 0;
  padding-top: 5%;
}

.linksAndLogo {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  width: 100%;
  align-items: flex-end;
}

.logo {
  width: 15vw;
}

.links {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  width: 50%;
}

.links a, .dropdownToggle, .dropdownMenu {
  font-weight: 500;
  text-decoration: none;
  color: black; 
}

.dropdownToggle {
  padding: 0;
  border: none;
}

.dropdownMenu {
  background-color: white;
  border: 1px solid black;
}

.dropdownItem {
  color: black;
  text-decoration: none;
  padding: 5%;
}

.address {
  font-weight: 500;
  text-decoration: none;
  color: black;
  width: 12%;
  text-align: right;
}

.address p {
  margin-bottom: 0 !important;
}

.socials {
  width: 5%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  gap: 2%;
}

.socials svg {
  color: #055487;
}

.footerText {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1em 0;
}

.extraInformation {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 5%;
}

.extraInformation a {
  text-decoration: none;
  color: black;
  white-space: nowrap;
}

@media screen and (max-width: 1330px) {
  .socials {
    width: 10%;
  }
  .links{
    width: 55%;
  }
}

@media screen and (max-width: 1024px) {
  .footerContainer {
    padding-top: 5%;
    gap: 2em;
  }

  .linksAndLogo {
    flex-direction: column;
    align-items: center;
    gap: 2em;
  }

  .logo {
    width: 25vh;
  }

  .links {
    width: 90%;
    padding: 1em 0;
  }

  .address {
    width: 100%;
    text-align: center;
  }

  .socials {
    width: 100%;
    justify-content: space-around;
  }

  .socials svg {
    width: 5vh;
    height: 5vh;
  }

  .footerText {
    flex-direction: column;
    gap: 1em;
  }

  .extraInformation {
    flex-direction: column;
    gap: 1em;
  }

  .extraInformation a {
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .links {
    flex-wrap: wrap;
    gap: 1em;
  }
  .footerText{
    padding: 1em;
  }

  .footerContainer{
    padding-top: 5% !important;
    gap: 1em;
  }

  .logo {
    width: 30vh;
  }
}

@media screen and (max-width: 425px) {
}
