.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
}

.box {
  width: 100% !important;
  display: flex;
  align-items: center;
  padding: 20px;
  background-image: url("/public/images/aboutBackground.png");
  background-position: top right;
  background-size: 75vw 65vh;
  background-repeat: no-repeat;
  min-height: 90vh;
}

.box img {
  width: 50vw;
  height: 100%;
  margin-left: 10%;
}

.textGroup {
  flex: 1 1;
  margin-right: 5rem;
  margin-left: 20px;
  text-align: end;
  position: relative;
}

.title {
  color: white;
  font-size: 6rem;
  font-weight: 600;
  filter: drop-shadow(3px 2px 3px rgba(0, 0, 0, 0.5));
}

.graphSection {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-height: 90vh;
  background-image: url("/public/images/about-images/laptopChart.png");
  background-position: 90%;
  background-size: 50% 70%;
  background-repeat: no-repeat;
  flex-grow: 1;
}

.graphText {
  width: 30%;
  margin-left: 5%;
  font-size: 2rem;
  font-weight: 600;
}

.graphText h3 {
  margin-left: 1%;
  font-size: 6rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.missionheader {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 5%;
  justify-content: center;
  text-align: center;
  font-size: 5rem;
}

.missionheader h1 {
  font-size: 5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.missionheader img {
  width: 15%;
  margin-right: 1%;
}

.mission {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2%;
  margin-top: 5%;
}

.missionCards {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin-top: 5%;
}

.missionCard {
  width: 35vh;
  height: 50vh;
  position: relative;
}

.missionCard > div {
  border-radius: 25px;
  position: absolute;
  transition: all ease 0.25s;
  backface-visibility: hidden;
}

.front {
  height: 100%;
  width: 100%;
  transform-style: preserve-3d;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-position: center;
  background-size: 75% 60%;
  background-repeat: no-repeat;
  color: white;
  text-transform: uppercase;
  padding: 2%;
}

.front img {
  border-radius: 1.2rem;
}

.front h2 {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
  margin-bottom: 1rem;
  white-space: nowrap;
  filter: drop-shadow(3px 2px 3px rgba(0, 0, 0, 0.5));
}

.back h2 {
  width: 100%;
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
  margin-bottom: 1rem;
  filter: drop-shadow(3px 2px 3px rgba(0, 0, 0, 0.5));
}

.back p {
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
  margin-bottom: 1rem;
  filter: drop-shadow(5px 3px 5px rgba(0, 0, 0, 0.747));
}

.back {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding-inline: 5%;
  height: 100%;
  width: 100%;
  transform: rotateY(180deg);
  background-color: #f0f0f0;
  position: absolute;
  text-align: center;
}

.missionCard:hover .front {
  transform: rotateY(180deg);
}

.missionCard:hover .back {
  transform: rotateY(0deg);
}

.vision {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-top: 5%;
  background-image: url("/public/images/about-images/certMountains.png");
  background-position: center bottom;
  background-size: 50% 50%;
  background-repeat: no-repeat;
}

.visionText {
  width: 80%;
  text-align: center;
}

.visionText h2 {
  font-size: 6rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.visionText p {
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 1rem;
}

.contactContainer {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  margin: 0;
  height: 100vh;
  background-color: #055487;
}

.contactForm {
  width: 50%;
  height: 100%;
  flex-grow: 1;
}

.formContent {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin: 0;
  height: 100%;
  gap: 2rem;
}

.formContent img {
  margin-top: 10%;
  width: 30vw;
  height: 15vw;
}

.formContent h2 {
  width: 100%;
  color: #fff;
  font-family: "Poppins-ExtraBold", Helvetica;
  font-size: 4.5vw;
  font-weight: 800;
  text-align: left;
  margin-bottom: 0;
}

.formText {
  margin-left: 5%;
  width: 50%;
  color: #fff;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 1.5vw;
  font-weight: 400;
  text-align: left;
}

@media screen and (max-width: 1330px) {
  .formContent {
    gap: 2.5rem;
  }

  .formContent img {
    width: 40vw;
    height: 20vw;
  }

  .formContent h2 {
    width: 90%;
    font-size: 6vw;
  }

  .box {
    background-size: 80vw 50vh;
    min-height: 80vh;
  }

  .box img {
    width: 55vw;
    margin-left: 2.5%;
    margin-bottom: 2.5%;
  }

  .textGroup {
    text-align: center;
    white-space: nowrap;
  }

  .title {
    font-size: 4rem;
  }

  .graphSection {
    background-size: 60% 70%;
    background-position: center right;
  }
}

@media screen and (max-width: 1024px) {
  .contactForm {
    width: 100%;
  }

  .formContent {
    display: none;
  }

  .box {
    background-size: 75vw 50vh;
    min-height: 80vh;
  }

  .box img {
    width: 55vw;
    margin-left: 1%;
    margin-bottom: 0;
  }

  .textGroup {
    text-align: center;
    white-space: nowrap;
  }

  .title {
    font-size: 4rem;
  }

  .graphSection {
    align-items: flex-start;
    background-size: 100% 65%;
    background-position: center bottom;
  }

  .graphText {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    border-radius: 20px;
    text-align: center;
  }

  .graphText h3 {
    margin-left: 0;
    font-size: 4rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .graphText p {
    margin-top: 0;
    margin-bottom: 1rem;
    width: 95%;
    font-size: 1.3rem;
  }

  .missionCards {
    gap: 2.5rem;
  }

  .vision {
    background-size: 100% 45%;
    background-position: center bottom;
    min-height: 70vh;
    margin-bottom: 0;
  }

  .contactContainer {
    margin-top: 0;
    height: 100vh;
  }
}

@media screen and (max-width: 768px) {
  .box {
    background-size: 165% 100%;
    min-height: 75vh !important;
    background-position: top right;
    gap: 6vh;
    flex-direction: column-reverse;
    justify-content: center;
  }

  .textGroup {
    text-align: center;
    flex: unset;
    margin: 0;
  }

  .title {
    font-size: 3.5rem;
  }

  .graphSection {
    background-size: 100% 70%;
    margin-top: 5vh;
  }

  .graphText h3 {
    text-align: center;
  }

  .mission {
    margin-top: 5vh;
  }

  .vision {
    background-size: 102% 45%;
    background-position: center bottom;
    margin-bottom: 0;
  }

  .visionText h2 {
    font-size: 4rem;
  }

  .visionText p {
    font-size: 1.3rem;
  }

  .contactContainer {
    margin-top: 0;
    height: 100vh;
  }

  .graphSection {
    background-size: 100% 50%;
    min-height: 70vh;
  }

  .missionheader h1 {
    font-size: 4rem;
    font-weight: 600;
  }

  .front h2 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 425px) {
  .graphSection {
    background-size: 100% 40%;
    min-height: 90vh;
  }

  .box img {
    width: 70vw;
  }

  .missionheader img {
    display: none;
  }

  .missionheader h1{
    font-size: 3rem;
  }

  .missionCard {
    width: 80vw;
    height: 50vh;
    h2 {
      font-size: 1.2rem;
    }
  }

  .back {
    h2 {
      font-size: 1.5rem;
    }
    p {
      font-size: 0.9rem;
    }
  }

  .vision {
    background-image: none;
    height: auto;
  }

  .visionText h2 {
    font-size: 3rem;
  }

  .visionText p {
    font-size: 1rem;
  }

  .contactContainer {
    height: 126vh;
  }

  .graphText h3 {
    font-size: 3rem;
  }

  .missionCards {
    gap: 1rem;
  }
}
