.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
}

.headerImgContainer {
  width: 50%;
}

.headerImg {
  width: 100%;
  height:auto;
}

.box {
  width: 100% !important;
  display: flex;
  align-items: center;
  padding: 20px;
  min-height: 90vh;
  max-width: 1950px;
}

.textGroup {
  flex: 1;
  margin-right: 20px;
  margin-left: 5rem;
}

.partnerWithCoitb {
  color: #000000;
  font-family: "Poppins-ExtraBold", Helvetica;
  font-size: 4rem;
  font-weight: 800;
}

.textWrapper {
  color: #000000;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 2rem;
  font-weight: 600;
  width: 100%;
}

.partnerContainer {
  margin: 2%;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Creates three columns; adjust if necessary */
  gap: 4rem; /* Adjusts space between cards */
  align-items: stretch;
  gap: 2rem;
  width: 90%;
}

.partnerSection {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
}

.contactContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0;
  height: 110.3vh;
  min-height: 900px;
  max-height: 900px;
  background-color:#055487;
}

.formContainer {
  max-width: 1950px;
  display: flex;
  height: 100%;
}

.form {
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

.formContent {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin: 0;
  height: 100%;
  gap: 2rem;
}

.formContent img {
  margin-top: 10%;
  width: 30vw;
  height: auto;
  max-width: 700px;
}

.formContent h2 {
  width: 70%;
  color: #fff;
  font-family: "Poppins-ExtraBold", Helvetica;
  font-size: 5rem;
  font-weight: 800;
  text-align: right;
}

@media screen and (max-width: 1330px) {
  .formContent {
    gap: 2.5rem;
  }

  .formContent img {
    width: 40vw;
    height: 20vw;
  }

  .formContent h2 {
    width: 90%;
    font-size: 6vw;
  }

  .box {
    min-height: 70vh;
  }

  .partnerWithCoitb {
    font-size: 3.5rem;
  }

  .textWrapper {
    font-size: 1.7rem;
  }
}

@media screen and (max-width: 1024px) {
  .form {
    width: 100%;
  }
  .textGroup {
    margin-top: 18vh;
  }
  .formContainer {
    width: 100%;
  }

  .formContent {
    display: none;
  }

  .box {
    background-size: 55vw 35vw;
    min-height: 60vh;
  }

  .partnerContainer {
    width: 90%;
    grid-template-columns: repeat(2, 1fr);
  }
  .partnerWithCoitb {
    font-size: 2.7rem;
  }

  .textWrapper {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 768px) {
  .box {
    max-height: 200px;
    flex-direction: column;
    justify-content: center;
  }

  .textGroup {
    margin-top: 18vh;
  }
  .headerImg {
    height: 100%;
    max-height: 400px;
  }

  .textGroup {
    flex: 1 1;
    text-align: center;
    margin: 0;
    gap: 2vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .textWrapper {
    width: 100%;
    text-align: center;
    font-size: 1.3rem;
  }

  .partnerWithCoitb {
    font-size: 2rem;
    margin-top: 20vh;
  }

  .partnerContainer {
    width: 100%;
    padding: 2%;
    grid-template-columns: 1fr;
  }

  .partnerSection {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

@media screen and (max-width: 425px) {
  .box {
    min-height: 70vh;
    flex-direction: column;
  }

  .headerImgContainer {
    width: 300px;
  }

  .textGroup {
    margin-top: 18vh;
  }

  .textWrapper {
    font-size: 1rem;
  }

  .partnerWithCoitb {
    font-size: 1.5rem;
  }
}
