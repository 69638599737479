.navParent {
  height: 7.5vh;
  width: 100%;
  position: fixed;
  top: 2.5%;
  left: 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  z-index: 11;
}

.logoContainer {
  width: 30vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  margin-left: 2%;
  top: 2.5%;
  z-index: 1;
  background-color: #fff;
  opacity: 0.8;
  border-radius: 8px;
  padding: 8px;
}

.logo {
  width: 100%;
  height: 100%;
  color: #fff;
}

.logoText {
  font-size: 1.4vh;
  width: 30vh;
  max-width: fit-content;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  text-align: left;
  position: absolute;
  bottom: -100%;
  font-weight: 600;
}

.navContainer {
  width: 50%;
  margin-right: 5%;
  height: 100%;
  border-radius: 3rem;
}

.items {
  background-color: #1e1b1b9d;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border-radius: 3rem;
  white-space: nowrap;
}

.navItem {
  text-decoration: none;
  color: white;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  border-radius: 3rem;
  margin: 0;
  height: 100%;
}

.navItem:hover {
  background-color: #1e1b1ba6;
  height: 100%;
}

.navItem button.btn {
  display: none;
}

.navDrop {
  border-radius: 3rem;
  z-index: 1;
  border: none;
  transition: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  margin: 0;
  height: 100%;
}

.navDrop:hover {
  background-color: #1e1b1ba6 !important;
  height: 100% !important;
}

.navDrop button {
  border-radius: 3rem;
  background-color: transparent !important;
  height: 100% !important;
  width: 100%;
  border: 0;
}

.dropDown {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #1e1b1b9d;
  border-radius: 2rem;
  z-index: 1;
  transition: none;
  padding: 0;
}

.dropDown a {
  display: block;
  color: white;
  text-align: left;
  padding: 1em;
  text-decoration: none;
  border-radius: 3rem;
  transition: none;
  text-align: left;
}
.dropDown a:hover {
  background-color: #1e1b1ba6;
  color: white;
}

.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;
  position: absolute;
  right: 2%;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #1e1b1b9d;
  transition: transform 5s ease;
  overflow: hidden;
}

.hamburgerLine {
  width: 50%;
  height: 3px;
  background-color: rgb(255, 255, 255);
  margin: 2px 0;
  transition: transform 0.3s ease;
}

.hamburger.open .hamburgerLine:nth-of-type(1) {
  transform: rotate(-45deg) translateY(-5px) translateX(-3px);
}

.hamburger.open .hamburgerLine:nth-of-type(2) {
  width: 80%;
  margin-left: 30%;
}

.hamburger.open .hamburgerLine:nth-of-type(3) {
  transform: rotate(45deg) translateY(5px) translateX(-3px);
}

@media screen and (max-width: 1330px) {
  .navParent {
    height: 6vh;
  }

  .navContainer {
    width: 60%;
    margin-right: 8%;
  }

  .items {
    width: 100%;
  }

  .navItem {
    width: 25%;
    font-size: 0.8em;
  }

  .navDrop {
    width: 25%;
  }

  .navDrop button {
    font-size: 0.8rem;
  }

  .logoContainer {
    width: 15vw;
  }

  .logoText {
    width: 100%;
    font-size: 0.7vw;
    font-weight: 800;
    bottom: -95%;
    margin-bottom: 0.5rem;
  }

  .logo {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .navParent {
    height: 4vh;
  }

  .navContainer {
    width: 60%;
    margin-right: 8%;
  }

  .items {
    width: 100%;
  }

  .navItem {
    width: 25%;
    font-size: 0.8em;
  }

  .navDrop {
    width: 25%;
  }

  .navDrop button {
    font-size: 0.8rem;
  }

  .logoContainer {
    width: 18vw;
  }

  .logoText {
    width: 100%;
    font-size: 0.85vw;
    font-weight: 800;
    bottom: -95%;
  }

  .logo {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .hamburger {
    right: 2em;
  }

  .hamburgerLine {
    width: 50%;
    height: 4px;
    background-color: rgb(255, 255, 255);
    margin: 2px 0;
    transition: transform 0.3s ease;
  }

  .navParent {
    justify-content: center;
  }

  .navContainer {
    justify-content: center;
    width: 80%;
    margin-right: 0;
  }

  .items {
    flex-direction: column;
    width: min-content;
    height: 80vh;
    position: absolute;
    right: -10%;
    top: 100%;
  }

  .navItem {
    width: 100%;
    font-size: 0.8em;
    text-align: center;
    white-space: break-spaces;
  }

  .navDrop {
    width: 100%;
  }

  .navDrop button {
    white-space: normal;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  .logoContainer {
    width: 35vw;
  }

  .logoText {
    width: 100%;
    font-size: 1.7vw;
    font-weight: 800;
    bottom: -95%;
  }

  .logo {
    width: 100%;
  }
}

@media screen and (max-width: 425px) {
  .hamburger {
    top: 0em;
    right: 2em;
  }

  .hamburgerLine {
    width: 60%;
  }

  .navParent {
    justify-content: center;
  }

  .navContainer {
    justify-content: center;
    width: 100%;
    margin-top: 6rem;
    margin-right: 3rem;
  }

  .items {
    height: 40vh;
    font-weight: 600;
    padding: 0.8rem 0.5rem;
  }

  .logoContainer {
    width: 45vw;
  }

  .logo {
    width: 100%;
  }
}

/* @media (max-width: 768px) {
  .navContainer {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    background-color: rgba(30, 27, 27, 0.9);
    transform: translateX(100%);
    transition: transform 0.3s ease;
  }

  .hamburger {
    display: block;
  }

  .items {
    display: block;
    text-align: left;
  }
} */
