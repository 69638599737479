.formContainer {
  width: 60vw;
  height: 1000px;
  margin-bottom: 100px;
  margin-top: 20px;
}

.contactContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
}

.heroOverlay {
  min-height: 100%;
  min-width: 100%;
}

.hero {
  width: 100%;
  z-index: -2;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("../../../public/images/contact-images/AdobeStock_268229063.jpeg");
  filter: brightness(0.7);
  background-position: center;
  background-size: cover;
}

.heroContent {
  width: 60vw;
  color: rgb(255, 255, 255);
  margin-top: 10em;
  margin-bottom: 5em;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.heroContent > h1 {
  font-size: 3.5em;
}

.heroContent > h4 {
  font-size: 2em;
}

.contactInfo {
  background-color: white;
  border-radius: 15px 15px 0px 0px;
  height: fit-content;
  padding: 15px;
  color: black;
}

.contactInfo > h4 {
  text-align: center;
}

.iconsContainer {
  display: flex;
  padding: 5px;
  gap: 10px;
  width: 100%;
  justify-content: space-around;
}

.icons {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
}

.topIcons,
.btmIcons {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
